import React from "react";

function Me() {
  return (
    <div>
      <svg
        viewBox="0 10 211.73 180"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <defs>
          <clipPath id="background-clip">
            <path
              d="M39 153.73s31.57 19.71 77.26 15.21 90.18-37.23 90.36-72.33-8.82-80.28-33.59-86.29C136.84-6.57 114.13-5.82 88-2.82S34.73 11.45 16.71 48.24C-1.5 66.64-4.88 125.2 39 153.73z"
              fill="none"
            />
          </clipPath>

          <linearGradient
            id="linear-gradient"
            x1="102.94"
            y1="154.47"
            x2="102.94"
            y2="36.93"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#fff5cc" />
            <stop offset="0.01" stop-color="#faf0c8" />
            <stop offset="0.19" stop-color="#c2b599" />
            <stop offset="0.35" stop-color="#998977" />
            <stop offset="0.47" stop-color="#806f62" />
            <stop offset="0.54" stop-color="#77655a" />
            <stop offset="0.6" stop-color="#77655a" />
            <stop offset="1" stop-color="#77655a" />
          </linearGradient>
        </defs>
        <path
          class="bg"
          d="M39 153.73s31.57 19.71 77.26 15.21 90.18-37.23 90.36-72.33-10.51-57-35.28-63-50.22 17-76.31 20-60.12-15.88-78.32 2.51S-4.88 125.2 39 153.73z"
          fill="#2986cc"
        />
        <g clip-path="url(#background-clip)">
          <g class="me">
            <g class="body">
              <path
                class="neck"
                d="M114.26 143.16v-14a9.22 9.22 0 10-18.43 0v14c-15.27 2.84-24.74 15.08-24.74 27.33H139c0-12.24-9.5-24.49-24.74-27.33z"
                fill="#E6BDA7"
              />
              <path
                class="top"
                d="M105.61 167c-30.17 0-25.36-40-25.36 15.84h25.35l25-2.14c-.05-55.79 5.17-13.7-24.99-13.7z"
                fill="#fff"
                stroke="#404040"
                stroke-width=".5"
              />
              <path
                class="left-shoulder"
                d="M95.82 142.87c-16 1.84-29.37 19.5-29.37 40h29.37z"
                fill="#404040"
              />
              <path
                class="right-shoulder"
                d="M114.23 142.67c15.76 1.85 29 19.6 29 40.2h-29z"
                fill="#404040"
              />
            </g>
            <path
              class="shadow-neck"
              d="M95.82 122.36h18.41v14.31s-10.5 5.54-18.41 0z"
              fill="#B5A199"
            />
            <g class="head">
              <g class="ear-left ear">
                <path
                  d="M63.52 105.14A8.21 8.21 0 0072 113.2a8.36 8.36 0 008.51-8.1A8.21 8.21 0 0072 97a8.36 8.36 0 00-8.48 8.14z"
                  fill="#E6BDA7"
                />
                <path
                  d="M68.54 104.48a17 17 0 014.14.41c1.07.31 1.94 1 3 1.31a.39.39 0 00.43-.57c-1.15-2.38-5.49-1.86-7.58-1.67a.26.26 0 000 .52z"
                  fill="#b5aa9a"
                />
              </g>
              <g class="ear-right ear">
                <path
                  d="M144.37 105.24a8.2 8.2 0 01-8.37 8.06 8.35 8.35 0 01-8.51-8.1 8.21 8.21 0 018.42-8.06 8.35 8.35 0 018.46 8.1z"
                  fill="#E6BDA7"
                />
                <path
                  d="M139.6 104c-2.1-.19-6.43-.72-7.59 1.67a.39.39 0 00.44.57c1.07-.26 1.92-1 3-1.31a17.51 17.51 0 014.15-.41.26.26 0 000-.52z"
                  fill="#b5aa9a"
                />
              </g>
              <g class="face">
                <rect
                  x="73.99"
                  y="48.26"
                  width="61.54"
                  height="80.49"
                  rx="26.08"
                  transform="rotate(180 104.76 88.5)"
                  fill="#E6BDA7"
                />
                <g class="inner-face">
                  <path
                    class="eyebrow-right"
                    d="M120.73 79a9 9 0 00-4-1.22 9.8 9.8 0 00-4.19.87"
                    fill="none"
                    stroke="#161616"
                    stroke-width="2.04"
                  />
                  <path
                    class="eyebrow-left"
                    d="M97.12 79.41a9.53 9.53 0 00-4-1.11 10.58 10.58 0 00-4.2.76"
                    fill="none"
                    stroke="#161616"
                    stroke-width="2.04"
                  />
                  <path
                    class="mouth"
                    d="M97 107.52s7.06 4.62 14 1.59"
                    fill="none"
                    stroke="#878581"
                    stroke-width="1.04"
                  />
                  <path
                    class="oh"
                    opacity="0"
                    d="M105.56,117.06c4-.14,5-2.89,4.7-5.64s-1.88-6.7-4.84-6.62-4.73,4.36-4.9,6.72S101.57,117.19,105.56,117.06Z"
                    fill="#262528"
                  />
                  <g class="eyes">
                    <path
                      class="eye-left eye"
                      d="M89.48 87.37c-.07 2.08 1.25 3.8 2.94 3.85s3.1-1.59 3.16-3.67-1.25-3.8-2.94-3.85-3.1 1.59-3.16 3.67z"
                      fill="#2b343b"
                    />
                    <path
                      class="eye-right eye"
                      d="M113.67 87.37c-.07 2.08 1.25 3.8 2.94 3.85s3.1-1.59 3.16-3.67-1.25-3.8-2.94-3.85-3.1 1.59-3.16 3.67z"
                      fill="#2b343b"
                    />
                    <path
                      class="eye-right-2 eye"
                      d="M114.11 88a5.72 5.72 0 002.48.72 6.46 6.46 0 002.59-.45"
                      opacity="0"
                      fill="none"
                      stroke="#282828"
                      stroke-width="1.04"
                    />
                    <path
                      class="eye-left-2 eye"
                      d="M89.85 88a5.77 5.77 0 002.56.3 6.48 6.48 0 002.49-.87"
                      fill="none"
                      opacity="0"
                      stroke="#282828"
                      stroke-width="1.04"
                    />
                  </g>
                  <path
                    class="dizzy dizzy-1"
                    opacity="0"
                    d="M113.61,87.6c.54-2.66,2.66-3.84,4.63-3.37A3.3,3.3,0,0,1,117,90.71a2.53,2.53,0,0,1-2-3,2.48,2.48,0,0,1,2.73-1.92A1.71,1.71,0,0,1,119.32,88a1.59,1.59,0,0,1-1.75,1.34c-.79-.1-1.41-.59-1-1.42s1-.72,1.22-.24"
                    fill="none"
                    stroke="#000"
                    stroke-width="0.75"
                  />
                  <path
                    class="dizzy dizzy-2"
                    opacity="0"
                    d="M96.15,87.27c-.54-2.66-2.66-3.84-4.63-3.37s-2.89,1.9-2.46,4a3.11,3.11,0,0,0,3.68,2.45,2.53,2.53,0,0,0,2-3A2.49,2.49,0,0,0,92,85.49a1.71,1.71,0,0,0-1.57,2.13A1.57,1.57,0,0,0,92.19,89c.79-.11,1.41-.6,1-1.43s-1-.72-1.22-.23"
                    fill="none"
                    stroke="#000"
                    stroke-width="0.75"
                  />
                  <path
                    class="nose"
                    d="M102.39 98.13s3.09 1.55 5.78 0"
                    fill="none"
                    stroke="#878581"
                  />
                  <path
                    class="glasses"
                    d="M133.54 81.76c-4.7-1.42-15.29-2.42-19.83-.45-5.82 2.17-3.18 1.57-8.55 1.17-5.36.4-2.74 1-8.55-1.18-7.3-2.55-15.58-.24-22.25.72v2.75c2.46.24 1.26 6.78 3.06 10.32 2.13 7.23 12.69 9.55 18.19 5.49 3.9-2 7.08-10.32 7.21-12.86 0-1.64 4.15-2.57 4.61.24.11 2.53 3.42 10.69 7.28 12.62 5.5 4 16 1.74 18.17-5.49 1.8-3.54 1.69-9.92 2.88-10.32s.74-2.67 0-2.75-1.02-.1-2.22-.26zM97.25 97.49C90.94 104.81 79 101.2 78 92.3c-.7-2.62-1-7.3 1.27-9.12s6.88-1.87 9.23-2c11.14-.26 16.62 5.6 8.75 16.31zm35.12-5.19c-3.71 17.2-27.26 7.42-22.09-7.36 1.87-3.11 9.09-3.84 11.55-3.73 8.07-.04 12.7 1.79 10.54 11.09z"
                    fill="#0f0f0f"
                    opacity=".48"
                  />
                </g>
                <svg x="70" y="40">
                  <path
                    class="hair-front"
                    d="M66,14.59c-.67-1.52-2.45-5.08-6-8-3.47-2.85-7.01-3.66-14-5-5.51-1.06-12.65-2.36-21-1-3.13,.51-5.49,1.25-7.16,1.86-2.33,.86-5.01,1.87-7.84,4.14-1.71,1.37-2.89,2.73-4,4-1.18,1.35-2.08,2.39-3,4-1.12,1.97-1.61,3.65-2,5C.54,21.21,0,23.06,0,25.59c0,2,.39,1.54,1,6,.35,2.54,.34,3.5,1,5,.6,1.37,1.4,2.36,2,3,.03-2.64,.55-4.68,1-6,.57-1.7,1.2-2.72,1.34-2.95,.48-.79,1.29-1.92,2.66-3.05,.53-.93,1.19-1.94,2-3,.75-.98,1.5-1.83,2.2-2.55-.72,1.06-1.47,2.24-2.2,3.55-.79,1.4-1.45,2.75-2,4,1.15-.03,3.66-.26,6-2,.89-.66,1.54-1.38,2-2,.74-.49,1.97-1.43,3-3,1.03-1.56,1.41-3.05,1.57-3.93,.09,.75,.15,2.26-.57,3.93-1.13,2.62-3.4,3.73-4,4,.52,.32,2.45,1.4,5,1,2.15-.34,3.52-1.54,4-2,.24-.02,1.76-.14,2.77-1.45,1.27-1.66,.51-3.66,.47-3.75,.29,.29,.58,.69,.76,1.21,.69,1.96-.87,3.84-1,4,1.29-.03,4.07-.26,7-2,2.83-1.68,4.34-3.9,5-5,1.76,.21,3.3,.33,3.35,.09,0-.03-.01-.06-.02-.07-.17-.17-1.55,.77-6.32,4.98,1.06,.45,3.22,1.2,6,1,2.92-.22,5.03-1.38,6-2,.37,.1,1.13,.26,2,0,1.69-.5,2.34-2.13,2.43-2.35,.83,.66,1.93,1.74,2.57,3.35,1,2.51,.15,4.48,1,5,.55,.34,1.81,.05,5-3,.69,2.93,1.36,5.93,2,9,.35,1.68,.68,3.35,1,5,.62-1.09,1.46-2.8,2-5,.27-1.12,.78-3.59,0-10-.61-5.02-.91-7.53-2-10Z"
                    fill="#fffff"
                  />
                </svg>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Me;
